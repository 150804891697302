<!--  登录   -->
<template>
  <div class="container">
    <div class="logo">
      <img class="tubiaoImg" :src="thumb" alt="">
      <img v-if="!isZKB" class="textImg" src="../../static/images/my_index/logo_text.png" alt="">
    </div>
    <div v-if="!wechatH5Auth" class="foot">
      <div v-if="canShowMp" class="wx_btn" @click="goWx">
        <img src="https://newoss.zhulong.com/forum/202303/07/15/1678174197449890.png" alt="">微信登录
      </div>
      <div v-else class="wx_btn" @click="account_login(2)">
        验证码登录
      </div>
      <div v-if="canShowMp" class="other">
        <span class="mima" @click="account_login(1)">密码登录</span>
        <span class="line">|</span>
        <span class="yzm" @click="account_login(2)">验证码登录</span>
      </div>
      <div v-else class="other" style="justifyContent:center;">
        <span class="mima" @click="account_login(1)">密码登录</span>
      </div>
      <div class="xy" @click="agreeClick">
        <img v-if="!agree" class="icon_gray" src="https://newoss.zhulong.com/forum/202303/07/16/1678176277865852.png" alt="">
        <img v-else class="icon_red" src="https://newoss.zhulong.com/forum/202303/07/16/1678176281454572.png" alt="">
        <p>我已阅读并同意<span style="text-decoration: underline;" @click.stop="toProtocol()">《用户协议》</span></p>
      </div>
      <van-dialog v-model="dialogShow" :show-confirm-button="false" :show-cancel-button="false" class="dialog" cancel-button-text="不同意" confirm-button-text="同意" title="温馨提示" @confirm="confirmClick()" @cancel="cancelDialog()">
        <div class="read">请阅读并同意<span @click="toProtocol()">《用户协议》</span></div>
        <div class="btns">
          <div @click="cancelDialog">不同意</div>
          <div @click="confirmClick">同意</div>
        </div>
      </van-dialog>
    </div>
    <div v-else class="foot">
      <div class="wx_btn1">微信授权登录</div>
    </div>
  </div>
</template>
<script>
import Cookies from 'js-cookie'
import { Dialog } from 'vant'
import { Toast } from 'vant'
import { getMiniUrlLink } from '@/api/login'
import { canShowForMp } from '@/api/my_user.js'
import WechatState from '@/utils/isWechatState.js'
import { getConfig, getSnsUserInfo, ischeckBind, InsetBind, ischeckBindByuid } from '@/api/login.js'

export default {
  data() {
    return {
      dialogShow: false,
      agree: sessionStorage.getItem('agree') || false,
      redirect: '',
      wx_url: '', // 跳转微信小程序链接
      pre_type: '',
      // true 不是小程序审核期间 可以展示微信登录，false 小程序审核期间，不展示微信登录
      canShowMp: false,
      thumb: '',
      isZKB: false,
      // 微信h5中 授权登录
      wechatH5Auth: false,
      aloading: false,
      // 微信公众号
      authGzhId: 'wxf3117cecab674509',
      auth_code: '', // 微信授权code
      openid: '', // 通过后台交换code获取openid
      unionid: '', // 通过后台交换code获取unionid
      headimgurl: '', // 通过后台交换code获取 headimgurl
      nickname: '', // 通过后台交换code获取 nickname
      sex: '' // 通过后台交换code获取 sex
    }
  },
  created() {
    // 获取公众号id
    this.getGzhConfigInfo()

    // 获取回调地址
    if (this.$route.query.redirect) {
      this.redirect = decodeURIComponent(this.$route.query.redirect)
    } else {
      this.redirect = window.location.origin
    }
    console.log(this.redirect, 'this.redirect')
    // 判断是否是注考帮
    const origin = window.location.origin
    this.isZKB = Cookies.get('agency_id') == 11 || origin.includes('zk468') || origin.includes('zkbpro')
    if (this.isZKB) {
      // 注考帮
      this.thumb = 'https://newoss.zhulong.com/forum/202403/14/35/144935jl86yt3x5dopva6l.png'
      this.$router.replace({ path: 'old_login', query: { redirect: this.redirect }})
    } else {
      // 筑龙
      this.thumb = 'https://newoss.zhulong.com/forum/202303/14/11/1678764929264659.png'
      // 判断环境
      if (this.checkWechatH5()) {
        // 微信浏览器下
        this.wechatH5Auth = true
        if (this.$route.query.code) {
          this.auth_code = this.$route.query.code
          // 检测是否绑定或绑定等
          this.getOpenIdFunc()
        } else {
          this.showAuthBind()
        }
      } else {
        // 非微信浏览器下：按照之前的来
        this.getAllConfig()
        this.getMiniUrlLink()
      }
    }
  },
  mounted() {
  },
  methods: {
    // 获取公众号id
    getGzhConfigInfo() {
      getConfig().then(res => {
        const result = res.result || {}
        const authorizer_app_id = result.authorizer_app_id
        if (authorizer_app_id && authorizer_app_id.length > 0) {
          this.authGzhId = authorizer_app_id
        }
      })
    },
    // 是否能够显示课程、直播
    getAllConfig() {
      canShowForMp().then(res => {
        console.log(res)
        const show = res.result || 0
        this.canShowMp = false// (show == 1)
      })
    },
    // 同意协议按钮
    agreeClick() {
      this.pre_type = ''
      if (this.agree) {
        this.agree = false
      } else {
        this.agree = true
      }
      sessionStorage.setItem('agree', this.agree)
    },
    // 同意协议按钮
    confirmClick() {
      console.log('同意')
      this.agree = true
      this.dialogShow = false
      sessionStorage.setItem('agree', this.agree)
      console.log(this.pre_type, ' -- pre')
      if (this.pre_type == 1 || this.pre_type == 2) {
        this.account_login(this.pre_type)
      } else if (this.pre_type == 3) {
        this.goWx()
      }
    },
    // 不同意协议按钮
    cancelDialog() {
      console.log('不同意')
      this.agree = false
      this.dialogShow = false
      sessionStorage.setItem('agree', this.agree)
    },
    // 微信登录
    goWx() {
      if (!this.agree) {
        this.pre_type = 3
        this.dialogShow = true
        return
      }
      if (this.wx_url) {
        window.location.href = this.wx_url
      } else {
        Toast({
          message: '跳转微信失败\n请使用其他方式登录',
          icon: 'close',
          className: 'noticeWidth'
        })
      }
    },
    // 获取小程序跳转链接
    getMiniUrlLink() {
      const str = this.redirect
      var share = encodeURIComponent(str)
      const info = {
        path: '/pages/login/login',
        query: `fromType=1&share=${share}`,
        env_version: 'release'
      }
      getMiniUrlLink(info).then(res => {
        console.log(res, 'res')
        if (res.result) {
          this.wx_url = res.result
        }
      })
    },
    account_login(type) {
      if (!this.agree) {
        this.pre_type = type
        this.dialogShow = true
        return
      }
      this.$router.push({
        path: 'other_login',
        query: {
          type: type,
          redirect: this.redirect
        }
      })
    },
    toProtocol() {
      this.$router.push({
        path: 'protocol'
      })
    },
    // 判断是微信h5环境
    checkWechatH5() {
      const isWxH5 = WechatState.isWechat && !WechatState.isWechatMiniprogram && !WechatState.wxwork
      return isWxH5
    },
    // 微信js授权绑定
    showAuthBind() {
    // 回调之后回到之前的页面
      var redirect = this.redirect
      redirect = encodeURIComponent(redirect)
      const origin = window.location.origin
      var auth_redirect = `${origin}/ucenter/login?redirect=${redirect}`// 微信授权回调页面
      console.log(auth_redirect, 'auth_redirect')
      auth_redirect = encodeURIComponent(auth_redirect)
      const appid = this.authGzhId
      const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${auth_redirect}&response_type=code&scope=snsapi_userinfo&state=success#wechat_redirect`
      window.location.href = url
    },
    // 根据授权code获取unionid和openid
    getOpenIdFunc() {
      this.aloading = true
      getSnsUserInfo({ code: this.auth_code }).then(res => {
        if (res.errNo == 0) {
          const result = res.result || {}
          if (result.openid) {
            this.openid = result.openid || ''
            this.unionid = result.unionid || ''
            this.nickname = result.nickname || ''
            this.headimgurl = result.headimgurl || ''
            this.sex = result.sex || 0
            // 检测是否绑定了网站
            this.checkIsBindWeb()
          } else {
            this.$message.error('授权失败')
          }
        } else {
          this.$message.error('授权失败')
        }
        this.aloading = false
      }).catch(() => {
        this.aloading = false
      })
    },
    // 检测是否绑定了网站
    checkIsBindWeb() {
      const query = {
        openid: this.openid,
        unionid: this.unionid,
        need_login: 1
      }
      ischeckBind(query).then(res => {
        const errNo = res.errNo
        const result = res.result || 0
        console.log(errNo, result)
        if (errNo == 0) {
          if (result > 0) {
            // 绑定过 -> 已写cookie
            window.location.href = this.redirect
          } else {
            // 未绑定 -> 去手机号登录，传过去参数：openid、unionid、nickname，登录之后绑定
            this.$router.replace({
              path: 'other_login',
              query: {
                type: 2,
                redirect: this.redirect,
                openid: this.openid,
                unionid: this.unionid,
                nickname: this.nickname,
                headimgurl: this.headimgurl
              }
            })
          }
        } else {
          this.$message.error(res.msg || '授权登录失败')
          this.wechatH5Auth = false
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>
* {
  box-sizing: content-box;
}
.container {
  // width: 100%;
  // overflow: hidden;
  height: 100vh;
  display: flex;
  flex-flow: column;
  .logo{
    // margin: 356px 0 816px;
    // text-align: center;
    flex: 1;
    padding: 129px 0 0;
    img{
      display: block;
      margin: 0 auto;
    }
    .tubiaoImg{
      width: 183px;
      height: 183px;
      margin-bottom: 29px;
    }
    .textImg{
      width: 188px;
    }
  }
  .foot{
    height: 480px;
    .wx_btn{
      width: 628px;
      height: 88px;
      border-radius: 10px;
      background: rgba(238, 46, 46, 1);
      color: rgba(255, 255, 255, 1);
      font-size: 32px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      img{
        width: 48px;
        height: 48px;
        margin-right: 6px;
      }
    }
    .wx_btn1{
      width: 628px;
      height: 88px;
      border-radius: 10px;
      color: #666;
      font-size: 32px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      img{
        width: 48px;
        height: 48px;
        margin-right: 6px;
      }
    }
    .other{
      width: 350px;
      margin: 35px auto 35px;
      color: #999999;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 28px;
      .mima{
        margin-left: 16px;
      }
      .line{
        color: rgba(187, 187, 187, 1);
      }
    }
    .xy{
      color:  #999;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        flex-shrink: 0;
        width: 35px;
        height: 35px;
        margin-right: 5px;

      }
      .icon_gray {
        opacity: 1;
      }
      .icon_red {
        opacity: 1;
        animation:animation01 0.75s;
      }
      @keyframes animation01 {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.5);
        }
        100% {
          transform: scale(1);
        }
      }
    }
  }
}
.dialog{
  /deep/.van-dialog__header{
    font-weight: 600;
    font-size: 36px;
  }
  .read{
    margin: 39px 0 52px;
    text-align: center;
    color: #999;
    font-size: 28px;
    span{
      text-decoration: underline;
    }
  }
  .btns{
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 96px;
    font-weight: 600;
    div{
      width: 50%;
    }
    div:first-child{
      color: #999;
    }
    div:last-child{
      color: #ee2e2e;
    }
  }
}
// /deep/.van-dialog__footer{
//   position: static;
// }
// /deep/.van-button{
//   position: static;
// }
</style>
<style>
.noticeWidth{
  width: 260px !important;
  height: 244px !important;
}
.noticeWidth .van-toast__icon{
  margin: 0 0 10px;
}
</style>
